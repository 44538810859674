import { FC } from "react";
import {Button} from "@mui/material";
import {uscheduleBookingUrl} from "../../utils/constants";
import "./styles.scss";


const BookNowButton: FC = () => {
  return (
    <div id="book-now" className="book-now">
      <a className="book-now-link" href={uscheduleBookingUrl}>
        <Button
          id="book-now-button"
          variant='outlined'
          color='primary'
          sx={{ fontSize: 30, fontWeight: 'bold' }}
        >
          BOOK NOW
        </Button>
      </a>
    </div>
  )
}

export default BookNowButton;
