import * as React from 'react';

import Stack from "@mui/material/Stack";

import {BookNowButton, Header} from "../../components";
import './styles.scss';


const Rates: React.FC = () => {
  return (
    <div id="rates-page" className="page">
      
      <Header title="Rates"/>
      
      <Stack direction="column" spacing={2} alignItems="center">
        <ul>
          <li>Monday: $20/hour</li>
          <li>Tuesday-Wednesday before 5 pm: $30/hour</li>
          <li>Thursday – Sunday: $40/hour</li>
        </ul>
        
        <BookNowButton/>
      </Stack>
    </div>
  )
};

export default Rates;
