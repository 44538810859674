import { FC } from 'react';
import { useNavigate } from 'react-router';
import { AppBar, Container, Toolbar, Box, Button } from '@mui/material';

import { ROUTES_OBJ } from '../../utils/routes';
import { LOGO, MD_DISPLAY, XS_DISPLAY } from './constants';
import TopNavigationMenu from './menu';
import './styles.scss';


const TopNavigation: FC = () => {
    const navigate = useNavigate();
    
    const buttonContent = (item: any) => {
        let content
        if (item?.isButton)
            content = item.display?.toUpperCase();
        else
            content = item?.display
        
        if (item?.link)
            return (
              <a className='book-now-link' href={item.link}>
                {content}
              </a>
            )
        
        return content
    }

    return <AppBar position='static' color='primary'>
        <Container disableGutters maxWidth="xl">
            <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
                <Box sx={{ display: MD_DISPLAY }} onClick={() => navigate("/")}>{LOGO}</Box>
                <Box sx={{ flexGrow: 1, display: XS_DISPLAY, ml: 1 }}>
                    <TopNavigationMenu />
                </Box>
                <Box sx={{ display: XS_DISPLAY }} onClick={() => navigate("/")}>{LOGO}</Box>
                <Box sx={{ display: MD_DISPLAY, mr: 2 }}>
                    {ROUTES_OBJ
                        .filter(({ display, hide }) => !!display && !hide)
                        .map(({ display, path, isButton, link }) => (
                            isButton ?
                                <Button
                                    id={path}
                                    key={path}
                                    variant='outlined'
                                    color="inherit"
                                    sx={{ ml: 2 }}
                                    onClick={() => !link && navigate(`/${path}`)}
                                >
                                    {buttonContent({ display, path, isButton, link })}
                                </Button> :
                                <Button
                                    id={path}
                                    key={path}
                                    sx={{ color: 'white', display: 'block' }}
                                    onClick={() => navigate(`/${path}`)}
                                >
                                    {buttonContent({ display, path, isButton, link })}
                                </Button>
                        ))}
                </Box>
            </Toolbar>
        </Container>
    </AppBar >
}

export default TopNavigation;
