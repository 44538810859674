
export const LIFECYCLE = window.LIFECYCLE ?? 'local';

export const getCurrentUrl = (port?: number): string => {
  if (!port) port = 8000
  
  if (window.location.hostname === 'localhost') return `http://localhost:${port}/`;
  
  return encodeURI(`https://${window.location.hostname}`);
};

const getApiUrl = (): string => {
  let url = window.API_URL
  if (!url) url = getCurrentUrl()
  return url + 'api'
}


export const API_URL = getApiUrl();

