import * as React from "react";
import "./styles.scss";

interface HeaderProps {
  title: string
}

const Header: React.FC<HeaderProps> = ({ title }) => {
  return (
    <header className="page-header">
      <h1 className="page-title">{title}</h1>
      <div className="header-divider"/>
    </header>
  )
}

export default Header;
