import { FC, useState, MouseEvent } from 'react';
import { useNavigate } from 'react-router';

import { Menu, MenuItem, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';

import { ROUTES_OBJ } from '../../utils/routes';
import { ANCHOR_ORIGIN, TRANSFORM_ORIGIN } from './constants';


export const TopNavigationMenu: FC = () => {
  const navigate = useNavigate();
  
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  
  const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  
  return <>
    <IconButton size="large" onClick={handleOpenNavMenu} color="inherit" >
      <MenuIcon />
    </IconButton>
    <Menu
      id="menu-appbar"
      anchorEl={anchorElNav}
      anchorOrigin={ANCHOR_ORIGIN}
      keepMounted
      transformOrigin={TRANSFORM_ORIGIN}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
      sx={{ display: { xs: 'block', md: 'none' }, }}
    >
      {ROUTES_OBJ
      .filter(({ display, hide }) => !!display && !hide)
      .map(({ display, path, isButton }) => isButton ?
        <MenuItem key={path} onClick={() => {
            handleCloseNavMenu();
            navigate(`/${path}`);
          }}
          color="primary"
          sx={{ backgroundColor: theme.palette.primary.main, color: 'white' }}>
          <Typography textAlign="center">{display?.toUpperCase()}</Typography>
        </MenuItem>
        : (
          <MenuItem key={path} onClick={() => {
            handleCloseNavMenu();
            navigate(`/${path}`);
          }}>
            <Typography textAlign="center">{display}</Typography>
          </MenuItem>
        ))}
    </Menu>
  </>
};

export default TopNavigationMenu;
