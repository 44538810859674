import * as React from 'react';

import {Header} from "../../components";
import './styles.scss';



const OurFood: React.FC = () => {
  
  return (
    <div id="food-beverage-page" className="page">
      
      <Header title="Food & Beverage"/>
      
      <div className='fullswing-food'>
        
        <div className='food-card'>
          <h1 className='food-header'>
            Take a break from the game and treat yourself to our concessions!
          </h1>
          
          <div className='fullswing-inhouse'>
            <div className='fullswing-inhouse-food'>
              <h2>Food</h2>
              <p>
                Brownies, Candy, Cookies, Fruit Snacks, Popcorn, Hotdog, Soft Pretzels, Cotton Candy, Pizza, Ice Cream, Nachos
              </p>
            </div>
            
            <div className='fullswing-inhouse-beverage'>
              <h2>Beverage</h2>
              <p>
                Gatorade, Juice, Sparkling Water, Water, Soda, Iced Tea/Tea, Root Beer, Coffee, Hot Chocolate, Smoothie
              </p>
            </div>
            <div className='fullswing-bar-description'>
              <h2>Luckyz Lounge</h2>
              <p>
                Whether you're looking to hangout after a game or gathering with friends, the LuckySwingz Lounge
                provides a nice setting to watch TV and enjoy a drink. Stay tuned for the grand opening at LuckySwingz Indoor Golf!
              </p>
              <div className='fullswing-inhouse-image'>
              <p>Coming Soon</p>
            </div>
            </div>

          </div>
          
          {/* HIDE FOR NOW UNTIL FOOD OPTIONS ARE FINALIZED */}
          {false && (
            <div className='outside-restaurants'>
              <p>
                While enjoying our simulators, we have a full variety of concessions to quickly indulge in. If you’re looking for the complete Lucky Swingz experience, Take a look at Legend’s Bar & Grill’s daily specials and appetizing menu!
              </p>
            
              <div className='legends-restaurant'>
                <img src="https://static.wixstatic.com/media/5be4d7_76252bc943fc4814833fb7076503594a~mv2.png/v1/fill/w_250,h_250,al_c/5be4d7_76252bc943fc4814833fb7076503594a~mv2.png" alt="Legends Grill logo" />
                
                <ul>
                  
                  <li>
                    <a href="https://www.legendsgrillbar.com/woodridge" target="_blank" rel="noreferrer">Legends Bar and Grill Woodridge</a>
                  </li>
                  
                  <li>
                    <a href="https://www.legendsgrillbar.com/menus" target="_blank" rel="noreferrer">Legends Menu</a>
                  </li>
                  
                  <li>
                    <a href="https://www.legendsgrillbar.com/specials" target="_blank" rel="noreferrer">Legends Daily Specials</a>
                  </li>
                  
                  <li>
                    <a href="https://order.toasttab.com/online/locations/a1609403-f5fe-4e0f-86a5-677d0541a66a/default" target="_blank" rel="noreferrer">Legends Order Online </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default OurFood
