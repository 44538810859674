import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { theme } from './style/theme'
import { ThemeProvider } from '@mui/material/styles';

const composeProviders = (...Providers: any[]) => (Child: any) => (
  props: any
): JSX.Element =>
  Providers.reduce(
    (acc, Provider) => <Provider>{acc}</Provider>,
    <Child {...props} />
  )

const WrappedApp = composeProviders(Router)(App)

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <WrappedApp />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);