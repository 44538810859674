import { FC } from 'react';
import { AppBar, Container, Toolbar, Box, Stack, Typography } from '@mui/material';

import { GolfBall } from '../../images'
import {
  wsccUrl,
  address,
  addressDescription,
  contactEmail,
  contactPhone,
} from '../../utils/constants';
import {Address, EmailComponent, Map, PhoneNumber} from "..";
import './styles.scss'
import * as React from "react";
import {Link} from "react-router-dom";


const Footer: FC = () => {
  
  return <AppBar position='static' color='primary' sx={{ top: 'auto', bottom: 0 }}>
    <Container disableGutters>
      <Toolbar disableGutters sx={{
        justifyContent: 'space-evenly',
        flexDirection: { xs: 'column', sm: 'row' },
        alignItems: { xs: 'center', sm: 'start' },
      }}>
        <Stack sx={{ display: 'flex', textAlign: 'center', p: 1 }}>
          <Typography variant='h6'>
            Hours
          </Typography>
          <Typography variant='caption'>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <Stack style={{ textAlign: 'left' }}>
                <h3>May - Sept.</h3>
                <div className='footer-time'>Mon.-Thurs.: <span> 10am - 8pm</span></div>
                <div className='footer-time'>Friday: <span>10am - 9pm</span></div>
                <div className='footer-time'>Saturday: <span>10am - 9pm</span></div>
                <div className='footer-time'>Sunday: <span>10am - 6pm</span></div>
              </Stack>
              <Stack style={{ color: 'gray', textAlign: 'left' }}>
                <h3>Oct. - April</h3>
                <div className='footer-time'>Mon.-Thurs.: <span> 10am - 8pm</span></div>
                <div className='footer-time'>Friday: <span>10am - 9pm</span></div>
                <div className='footer-time'>Saturday: <span>10am - 9pm</span></div>
                <div className='footer-time'>Sunday: <span>10am - 6pm</span></div>
              </Stack>
            
            </Stack>
          </Typography>
        </Stack>
        <Stack sx={{ display: 'flex', textAlign: 'center', p: 1 }}>
          <Typography variant='h6'>
            Contact Us
          </Typography>
          <Typography variant='caption'>
            <div className='underlined'>
            <EmailComponent emailAddress={contactEmail} />
            </div>
          </Typography>
          <Typography variant='caption'>
            <div className='underlined'>
              <PhoneNumber phoneNumber={contactPhone} />
            </div>
          </Typography>
          <Typography variant='caption'>
            <Link id="privacy" to="/privacy" className='underlined'>
              Privacy Policy
            </Link>
          </Typography>
          <Box sx={{ pt: 1.5, display: { xs: 'none', sm: 'block' } }}>
            <GolfBall height="24" />
          </Box>
        </Stack>
        <Stack sx={{ display: 'flex', textAlign: 'center', p: 1 }}>
          <Typography variant='h6'>
            Visit Us
          </Typography>
          <Typography variant='caption'>
            <div className='footer-address'>
              <Address address={address}/>
            </div>
            <a className='underlined' href={wsccUrl} target="_blank" rel="noreferrer">
              {addressDescription}
            </a>
          </Typography>
          <Box sx={{ pt: 1.5, display: { xs: 'none', sm: 'block' } }}>
            <GolfBall height="24" />
          </Box>
        </Stack>
        <Stack className='directions' sx={{ display: 'flex', textAlign: 'center', p: 1 }}>
          <Typography variant='h6'>
            Directions
          </Typography>
          <Map id='footer' location={address} />
        </Stack>
      </Toolbar>
    </Container>
  </AppBar >
}

export default Footer;
