import * as React from 'react';

import {Address, BookNowButton, Header, Map} from '../../components';
import {address} from "../../utils/constants";
import './styles.scss';


const Home: React.FC = () => {
  return (
    <div id="home-page" className="page">
      <Header title="LuckySwingz Opening Soon!"/>
      <BookNowButton/>
      <Address address={address} />
      <Map id='home-map' location={address}/>
    </div>
  )
};

export default Home;
