import React from 'react';
import { useRoutes } from 'react-router';
import LogRocket from 'logrocket';

import { ROUTES_OBJ } from './utils/routes';
import { TopNavigation, Footer } from './components';
import './style/main.scss';
import {LIFECYCLE} from "./utils/env";

if (LIFECYCLE === 'prod')
  LogRocket.init('n0phks/prod-luckyswingz')

declare global {
  interface Window {
    API_URL: string,
    LIFECYCLE: string,
    APP_CODE: string;
    PUBLIC_BUCKET: string;
    BACKEND_BUCKET: string;
    GOOGLE_MAPS_KEY: string;
  }
}

const App: React.FC = () => {
  const routes = useRoutes(ROUTES_OBJ);
  return <>
    <TopNavigation />
    {routes}
    <Footer />
  </>;
}

export default App;
