export const fixGoogleFont = () => {
  const [head] = document.getElementsByTagName('head');
  // Save the original method
  const { insertBefore } = head;

  // Replace it!
  head.insertBefore = (newElement, referenceElement) => {
    if (
      newElement.href &&
      newElement.href.indexOf(
        'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Google+Sans:400,500,700|Google+Sans+Text:400&lang=en'
      ) === 0
    ) {
      return;
    }

    insertBefore.call(head, newElement, referenceElement);
  };
};

export default fixGoogleFont;
