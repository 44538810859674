import * as React from "react";
import './styles.scss';

interface PhoneNumberProps {
  phoneNumber: string
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({ phoneNumber }) => {
  const cleanNumber = phoneNumber.replace(/[^0-9]/g, '');
  return (
    <a className="secondary" href={`tel:+1${cleanNumber}`}>
      {phoneNumber}
    </a>
  )
}

export default PhoneNumber;
