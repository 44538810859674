import * as React from 'react';

import {Header} from "../../components";
import {contactEmail} from "../../utils/constants";
import {EmailComponent} from "../../components";
import './styles.scss';

const Leagues: React.FC = () => {
    return (
        <div id="leagues-page" className="page">
            <Header title="Leagues"/>
            <div className='card-together'>
            <div className='leagues-card-header'>
              <h1>Sign Up today for Indoor Golf Leagues!</h1>
              <div className='leagues-info'>
                <p>Fall, Winter, and Spring leagues available</p>
                <EmailComponent emailAddress={contactEmail} />
              </div>
            </div>

            <div className='leagues-card-images'>
              <img src="https://www.fullswinggolf.com/_next/image/?url=https%3A%2F%2Fshop.fullswinggolf.com%2Fwp-content%2Fuploads%2F2023%2F10%2FLadies-Golfing_Standard_New-UI.jpg&w=3840&q=75" alt="women golfing" />
              <img src="https://i.pinimg.com/originals/33/a5/9b/33a59be2247aecc3884330d7189ab4bc.jpg" alt="men golfing" />
            </div>
          </div>
        </div>
    )
};

export default Leagues;
