import { FC, useEffect, useState } from 'react';
import { Alert, Box, Stack } from '@mui/material';
import { BallTriangle } from "react-loader-spinner";
import { Loader } from '@googlemaps/js-api-loader';

import { googleMapsKey } from '../../utils/constants';
import useGeocode from '../../utils/geocode';
import { fixGoogleFont } from '../../utils/google-map-fix';
import './style.scss';

interface MapProps {
  id: string;
  location: string;
}

const loader = new Loader({
  apiKey: googleMapsKey,
  version: 'weekly'
});

export const GoogleMap: FC<MapProps> = ({ location, id }) => {
  const fullId = `map-${id}`
  
  const [geoRes, setGeoRes] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const { geocode } = useGeocode();
  
  
  useEffect(() => {
    setLoading(true);
    geocode(location)
      .then((res: any) => {
        setLoading(false);
        setGeoRes(res);
      })
      .catch((err: any) => {
        setLoading(false);
        setError(err.message);
      });
  }, [geocode, location]);
  
  useEffect(() => {
    fixGoogleFont();
  }, []);
  
  useEffect(() => {
    if (geoRes) {
      loader.importLibrary('maps')
      .then(({ Map }) => {
        const box = document.getElementById(fullId);
        if (box) {
          /* eslint-disable-next-line */
          new Map(box, {
            center: geoRes,
            zoom: 15
          });
        }
      })
      .catch(e => {
        console.log('Error loading map:', e);
        setError('Error loading map');
      });
    }
  }, [geoRes, fullId]);
  
  return (
    <Stack spacing={1} className="google-map" pt={2}>
      {error && (
        <Alert severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      )}
      <BallTriangle
        height={40}
        width={40}
        color="#4fa94d"
        ariaLabel="ball-triangle-loading"
        visible={loading}
      />
      {!loading && !error && (
        <Box
          id={fullId}
          className="google-map--box"
        />
      )}
    </Stack>
  );
};

export default GoogleMap;
