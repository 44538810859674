import { RouteObject } from 'react-router-dom';

import {
  Home,
  Rates,
  OurTechnology,
  Food,
  JuniorCamp,
  Lessons,
  Leagues,
  PrivacyPolicy,
} from "../pages";
import {uscheduleBookingUrl} from "./constants";


export const ROUTES_OBJ: (RouteObject & { display: string, isButton?: boolean, hide?: boolean, link?: string })[] = [
  {
    path: '',
    display: "",
    element: <Home/>
  },
  {
    path: 'home',
    display: "Home",
    element: <Home/>
  },
  {
    path: 'rates',
    display: "Rates",
    element: <Rates/>
  },
  {
    path: 'our-technology',
    display: "Our Technology",
    element: <OurTechnology/>
  },
  {
    path: 'food-beverage',
    display: "Food & Beverage",
    element: <Food/>
  },
  {
    path: 'lessons',
    display: "Golf Lessons",
    element: <Lessons/>,
  },
  {
    path: 'junior-camp',
    display: "Junior Golf Camp",
    element: <JuniorCamp/>,
  },
  {
    path: 'leagues',
    display: "Leagues",
    element: <Leagues/>,
  },
  {
    path: 'privacy',
    display: "",
    element: <PrivacyPolicy/>
  },
  {
    path: 'booking',
    display: "Book Now",
    isButton: true,
    link: uscheduleBookingUrl,
  },
]
