import { useCallback } from 'react';
import { setKey, setRegion, fromAddress } from 'react-geocode';

import { googleMapsKey } from '../utils/constants';


export default function useGeocode() {
  setKey(googleMapsKey);
  setRegion('us');

  const handleGetGeocode = useCallback(
    (address: string) =>
      fromAddress(address).then(
        (response: any) => {
          const {
            results: [
              {
                geometry: { location }
              }
            ]
          } = response;

          return location;
        },
        (e: any) => e
      ),
    []
  );

  return { geocode: handleGetGeocode };
}
