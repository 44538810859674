import * as React from 'react';

import {Header, EmailComponent,  PhoneNumber} from "../../components";
import {golfProEmail, golfProNumber} from "../../utils/constants";
import './styles.scss';

const Lessons: React.FC = () => {
  return (
    <div id="lessons-page" className="page">
      <Header title="Golf Lessons"/>
      
      <div className="coach-profile" >
        <div className='coach'>
          <img src="https://m.cbhomes.com/a/-1/146788/0bLZdbb563Pg/original.jpg" alt="Brent Jensen, golf coach" />
          <div className="coach-info-container">
            <h1>Brent Jensen</h1>
          </div>
          <h3>
            <a className='primary' href={`mailto:${golfProEmail}`}>
              Email Brent to schedule a lesson!
            </a>
          </h3>
        </div>
        
        <div className="coach-description">
          <h2>Luckyswingz Golf Pro Coach</h2>
          <p>Brent is a natural golf pro and great coach. His success stems from a strong commitment to personal growth
            and goal achievement, bolstered by exceptional listening and communication skills.
          </p>
          <p>
            Brent started his career as a player at Saint Mary's College of California where he earned WCC Player of the Month,
            and eventually became an assistant coach. He was also a coach at Florida Gulf Coast University and the
            University of South Florida, where he helped lead the team to win the American Conference Championship.
          </p>
          <p>
            Brent has a history of competing professionally as well. His passion and expertise for golf greatly benefit
            his clients. Raised in Chicago's western suburbs, Brent has a deep love for the community, the game of golf,
            and all it offers.
          </p>
        </div>
      </div>
      
      <div className="coach-price">
        <p className='hour'>$125/<span className='span-hour'>HOUR</span></p>
        <div className='coach-contact-info'>
          <p>For any questions contact Brent!</p>
          <EmailComponent emailAddress={golfProEmail} />
          <PhoneNumber phoneNumber={golfProNumber}/>
        </div>
      </div>
    </div>
  )
};

export default Lessons;
