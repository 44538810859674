import { FC } from "react";
import "./styles.scss";

interface AddressProps {
  address: string
}

const AddressComponent: FC<AddressProps> = ({ address }) => {
  
  const openMap = () => {
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`,
      '_blank'
    );
  }
  
  return (
    <div className='address' onClick={openMap}>
      {address}
    </div>
  )
}

export default AddressComponent;
