import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#48655A'
        },
    },
    typography: {
        fontFamily: 'Roboto, Quicksand, sans-serif'
    }
});