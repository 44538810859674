import * as React from 'react';
import Stack from '@mui/material/Stack';

import {EmailComponent, Header, PhoneNumber} from '../../components';
import './styles.scss';
import {golfProEmail} from "../../utils/constants";

const JuniorCamp: React.FC = () => {
    return (
        <div id="junior-camp-page" className="page">
            <Header title="Junior Golf Camp"/>

            <Stack className='divide-one' direction="row" spacing={2}>
            <img className="fullswing-image" src="https://cdn-libaj.nitrocdn.com/FNfcxsaVJanGsnUiWxFwkbFanHLbQRBg/assets/images/optimized/wp-content/uploads/2023/12/05d80d2315507d24b4edf74c146396bf.Kids-Playing-On-Golf-Simulator.jpg" alt=""/>
            <div className='golf-card-one secondary'>
                <h1 className='golf-title'>LuckySwingz Indoor Junior Golf Camp</h1>
                <p className='golf-description secondary'>
                  Join us for 5 days of excitement and learning at our junior golf camp.
                  Young golfers will enhance their skills through cutting-edge practice lessons and exercises tailored
                  to their level, using our state-of-the-art simulators. To wrap up each session, they can have fun with
                  Full Swing's exclusive multi-sport software, experiencing other favorite sports like baseball, football,
                  hockey, lacrosse, soccer, and more.
                </p>
                <div className='contact-info'>
                  <h3 className='register'>Camp is led by our golf pro Brent Jensen</h3>
                  <h4 className='register'>Contact Brent to register for one or more weeks!</h4>
                  <br/>
                  <br/>
                  <EmailComponent emailAddress={golfProEmail} />
                  <p className='phone-number secondary'>
                    <PhoneNumber phoneNumber="(630) 882-5390"/>
                  </p>
                </div>
            </div>
            </Stack>

            <div className="golf-card-two" >
            <Stack className='divide-two' direction="row" spacing={2}>
                <div className="weekly-price">
                  <h3>Monday-Friday</h3>
                  <h2 className='junior-price'>$225/<span className='mobile-week'>week</span></h2>
                </div>

                <div className='date-time'>
                  <Stack direction="row" className='date-time-jr' spacing={12}>
                    <Stack direction="row">
                      <div className='date'>
                        <h2>Dates:</h2>
                        <p><strong>Week 1:</strong> July 8 - 12</p>
                        <p><strong>Week 2:</strong> July 15 - 19</p>
                        <p><strong>Week 3:</strong> July 22 - 26</p>
                        <p><strong>Week 4:</strong> July 29 - August 2</p>
                        <p><strong>Week 5:</strong> August 5 - August 9</p>
                      </div>
                    </Stack>
                    <Stack className='jr-time' direction="row">
                      <div className='time'>
                        <h2>Times:</h2>
                        <p><strong>Ages 7-9:</strong>   9:30am-12pm</p>
                        <p><strong>Ages 10-13:</strong> 12pm-2:30pm</p>
                      </div>
                    </Stack>
                  </Stack>
                </div>
            </Stack>
            </div>

        </div>
    )
};

export default JuniorCamp;
