
export const contactEmail = 'contact@luckyswingz.com'
export const contactPhone = '(630) 882-5390'

export const golfProEmail = 'brent@luckyswingz.com'
export const golfProNumber = '(630) 882-5390'

export const uscheduleBookingUrl = 'https://clients.uschedule.com/luckyswingz/booking'

export const wsccUrl = "https://www.westsuburbansportscomplex.com/"
export const address = '6200 River Bend Drive, Lisle, IL 60532'
export const addressDescription = '3rd floor of West Suburban Sports Complex'

export const googleMapsKey = 'AIzaSyC3h8AwlgP-BAYG2-aTg8dmm8JdzoNpLcA'

