import { LuckySwingzMenu } from '../../images'
import { PopoverOrigin } from '@mui/material';

export const LOGO = <LuckySwingzMenu height={100} width={400} />;

export const ANCHOR_ORIGIN: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
};

export const TRANSFORM_ORIGIN: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'left',
};

export const MD_DISPLAY = { xs: 'none', md: 'flex' };
export const XS_DISPLAY = { xs: 'flex', md: 'none' };
