import * as React from 'react';
import Stack from '@mui/material/Stack';

// import Carousel from 'react-material-ui-carousel'

import {Header} from "../../components";
import './styles.scss';


const OurTechnology: React.FC = () => {
  // const items: string[] = [
  //   "https://image.benq.com/is/image/benqco/golf-simulator-thumbnail?$ResponsivePreset$",
  //   "https://cdn.mos.cms.futurecdn.net/poDH86XrPDSfqeT8rBd8UG.jpg",
  //   "https://www.fullswinggolf.com/_next/image/?url=https%3A%2F%2Fshop.fullswinggolf.com%2Fwp-content%2Fuploads%2F2023%2F11%2FDSC07222-scaled.jpg&w=384&q=75"
  // ]

  return (
    <div id="our-technology-page" className="page">
      
      <Header title="Our Technology"/>
      
      <div className="jumbotron">
      <Stack className="jumbotron-content" direction="row" spacing={2} >
        
      <div className='jumbotron-card-left'>
      <img className='jumbotron-image-left' src="https://shop.fullswinggolf.com/wp-content/uploads/2023/11/Josh-Allen-1.jpg" alt="golf player" />
      </div>
      
      <div className='jumbotron-card-right'>
        <img className='jumbotron-image-right' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTpbcza3GMFJDbBAJzAKG7YJBSo155Z9caWxw&s" alt="" />

        <div className="jumbotron-buttons">
          <a href="https://www.fullswinggolf.com/full-swing-golf-software/" target="_blank" rel="noreferrer">FULLSWING</a>
          
          <a href="https://www.dropbox.com/s/jwc7virxixi58ro/Full%20Swing%20Golf%20-%20Current%20Course%20List.pdf?e=3&dl=0" target='_blank' rel="noreferrer" >Course List</a>
        </div>
      </div>
      </Stack>
      </div>

      <div className='tech-description'>
        <p>FullSwing Golf Simulators use Patented dual-tracking technology, now advanced to tri-tracking. With the Full Swing KIT launch monitor, endorsed by Tiger Woods, golfers receive 16 data points for club and ball, along with high-resolution video, enhancing both indoor and outdoor play with an immersive virtual gaming experience. The elite team of Full Swing users includes champions such as Tiger Woods, Jon Rahm, Xander Schauffele, Jordan Spieth, and Dustin Johnson. </p>
      </div>

      <Stack direction="row" className="three-images">
        <div className='courses-tech img-together'>
          <img src="https://shop.fullswinggolf.com/wp-content/uploads/2020/07/MVGCBlog-1.jpg" alt="golf course" />
          <h2>Courses & Tech </h2>
          <ul>
            <li>Patented Dual & Tri-Tracking Technology, ION3 camera, and 4 high-speed Linescan cameras capture club and ball flight data</li>
            <li>Revolutionary golf software offering unparalleled realism, excitement, and jaw-dropping graphics</li>
            <li>Full Swing's status as the official licensee of The PGA TOUR & TPC Network has allowed them to establish partnerships across the globe</li>
          </ul>
        </div>
        <div className='practice-features img-together'>
          <img src="https://shop.fullswinggolf.com/wp-content/uploads/2023/11/Tiger-Woods_Pro-2_Lights.jpg" alt="Tiger Woods" />
          <h2>Practice Features</h2>
          <ul>
            <li>Precision drives on the Golf Channel Driving Range</li>
            <li>Over 30 different target pins & two drivable fairways</li>
            <li>Short game skills with 360-degree approach tactics on multi-tiered greens</li>
          </ul>
          </div>

        <div className='multisport-software img-together'>
          <img src="https://www.fullswinggolf.com/_next/image/?url=https%3A%2F%2Fshop.fullswinggolf.com%2Fwp-content%2Fuploads%2F2023%2F11%2FHockey-Practice-copy-scaled.jpeg&w=3840&q=75" alt="man playing hockey" />
          <h2>MultiSport Software</h2>
          <ul>
            <li>Face-to-face competitions and skill challenges that extend beyond the traditional course with FullSwing’s MultiSport Software</li>
            <li>Showdown Golf holds arcade-style contests like Closest to the Pin challenges and Long Drive Grid, players showcase their power off the tee</li>
            <li>Enjoy other favorites including Full Swing Football, Zombie Dodgeball, Darts, Baseball, Hockey, Lacrosse, Soccer, and over 13 diverse sports</li>
          </ul>
          </div>

      </Stack>
      
      {/* <Carousel>
        {
          items.map( (item, i) => <img key={i} src={item} alt="n/a" style={{width: "100%", height: "400px"}}/>)
        }
      </Carousel> */}

    </div>
  )
};

export default OurTechnology;
