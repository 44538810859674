import { FC, ReactNode } from "react";
import "./styles.scss";

interface EmailProps {
  emailAddress: string
  children?: ReactNode
}

const EmailComponent: FC<EmailProps> = ({ emailAddress, children }) => {
  
  return (
    <a className='secondary email' href={`mailto:${emailAddress}`}>
      {children ? (
        <span>{children}</span>
        ) : (
          <span>{emailAddress}</span>
        )}
    </a>
  )
}

export default EmailComponent;
